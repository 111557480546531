import './Settings.less';
import { Tabs } from '@imprivata-cloud/components';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { useSession } from '../../context/Session';
import useEntraIdInitialSync from '../../hooks/useEntraIdInitialSync';
import Appearance from '../appearance/Appearance';
import Directories from '../directories/Directories';
import Groups from '../groups/Groups';
import Integrations from '../integrations/Integrations';

const Settings = ({ activeTab }: { activeTab?: string }) => {
  const [searchParams] = useSearchParams();
  const { emitError } = useNotifications();
  const { mutate: entraIdInitialSync, isPending } = useEntraIdInitialSync();
  const { tenantId } = useSession();

  console.log(`${window.location.pathname}`);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/entraid/auth-success') {
      // entra id microsoft error handling
      if (searchParams.get('error')) {
        emitError(new AppError({ code: ErrorCode.ENTRA_ID_AUTH_ERROR }));
      } else {
        // complete entra id setup
        entraIdInitialSync(tenantId || '');
      }
    }
  }, [searchParams, emitError, entraIdInitialSync, tenantId]);

  const tabItems = [
    {
      key: 'users',
      label: 'Users',
      children: <div>Content of Users</div>,
    },
    {
      key: 'groups',
      label: 'Groups',
      children: <Groups />,
    },
    {
      key: 'directories',
      label: 'Directories',
      children: <Directories />,
    },
    {
      key: 'integrations',
      label: 'Integrations',
      children: <Integrations />,
    },
    {
      key: 'customize',
      label: 'Customize',
      children: <Appearance />,
    },
  ];

  if (isPending) {
    return <div> </div>;
  }

  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>Administration</h1>
      </div>
      <div className="settings-tab-container">
        <div className="settings-tab-header">
          <Tabs
            tabItems={tabItems}
            type="card"
            size="large"
            data-testid="settings-tabs"
            defaultActiveKey={activeTab || 'users'}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;

import type { PropsWithChildren } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { userLogout } from '../../../api/portalServices';
import { SESSION_ERROR_CODES } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { CUSTOMER_EXPERIENCE_CENTER_URL } from '../../constants';
import { useSession } from '../../context/Session';
import { useIdleTimeout } from '../../hooks/useIdleTimeout';
import LandingPageHeader from '../landing/LandingPageHeader';

const PortalLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tenantId, clearSession } = useSession();
  const { emitError } = useNotifications();

  const handleSettings = () => {
    console.log('Settings clicked: ');
    const params = new URLSearchParams(searchParams);
    navigate({
      pathname: '/settings',
      search: `?${params.toString()}`,
    });
  };

  const handleLogout = async () => {
    console.debug('Logout');
    if (tenantId) {
      await userLogout(tenantId)
        .then(() => {
          console.log('Logged out');
          clearSession();
        })
        .catch((e) => {
          if (Object.values(SESSION_ERROR_CODES).includes(e.code)) {
            console.debug('[Log out] Session error:', e);
            clearSession();
          }
          console.error('Error logging out:', e);
          emitError(new AppError(e));
        });
    }
  };

  const { startIdleTimer } = useIdleTimeout(30, handleLogout);
  startIdleTimer();

  return (
    <div className="landing-page">
      <LandingPageHeader userLogout={handleLogout} handleSettings={handleSettings} />
      <div className="page-header-container">
        <div className={'page-header'}>
          <a href={CUSTOMER_EXPERIENCE_CENTER_URL} target={'_blank'} rel={'noreferrer'}>
            CUSTOMER EXPERIENCE CENTER
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PortalLayout;

import ErrorImg from '../../../assets/icons/dead-link-setup.svg?react';
import './LinkDeadErrorView.less';
import { getPortalUrl } from '../../../utils/utils';

const LinkDeadErrorView = () => {
  const portalLink = getPortalUrl();
  return (
    <div className={'link-dead-container'}>
      <ErrorImg />
      <h3 className={'link-dead-text'}>Initial configuration has already been completed</h3>
      <span>
        Go to <a href={portalLink}>{window.location.host}</a> to log in
      </span>
    </div>
  );
};

export { LinkDeadErrorView };

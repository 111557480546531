import { Form, Modal } from 'antd';
import type { FormInstance } from 'antd';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, InputBox } from '@imprivata-cloud/components';
import { useNavigateToNextStep } from '../../setup/hooks/useNavigateToNextStep';
import { getSPMetadataURL } from '../../utils/utils';
import { STEPS } from '../../setup/constants';
import { StorageKeys } from '../../api/constants';
import ContinueButton from '../continueButton/ContinueButton';
import QuestionSvg from '../../assets/icons/question.svg?react';
import CopyButton from '../copyButton/copyButton';
import './IdpInfoForm.less';

const TRANSLATION_ROOT = `setup.${STEPS.EXTERNAL_IDP_INFO}.content`;
const FILE_INPUT_ID = 'xml-file-input';

interface FormValues {
  IdPName: string;
  IdPURL: string;
  IdPXML: string;
  IdPGroups?: string;
}

const IdpInfoForm = ({
  form,
  modalOnClose,
  isModalView,
  children,
  onFinish,
}: {
  form: FormInstance;
  modalOnClose?: () => void;
  isModalView?: boolean;
  children?: React.ReactNode;
  onFinish: (values: FormValues) => void;
}) => {
  const { t } = useTranslation();
  const navigateToNextStep = useNavigateToNextStep();

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [isIdpUrl, setIsIdpUrl] = useState<boolean>(false);
  const [readOnlyXML, setReadOnlyXML] = useState<boolean>(true);
  const spMetadataURL = getSPMetadataURL();
  const [mustConnectModalVisible, setMustConnectModalVisible] = useState(false);

  const handleInputChange = useCallback(() => {
    const values = form.getFieldsValue() as FormValues;
    const isEnabled = Boolean(values.IdPName && (values.IdPURL || values.IdPXML));
    setIsIdpUrl(!!values.IdPURL);
    setIsSubmitEnabled(isEnabled);
  }, [form.getFieldsValue]);

  const handleInputBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
      setReadOnlyXML(true);
    } else {
      setFileName(inputValue);
      setReadOnlyXML(false);
    }
  };

  const handleInputBoxFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputBoxKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    } else {
      event.preventDefault();
      form.setFieldsValue({ IdPXML: '' });
      setFileName('');
      setReadOnlyXML(true);
      setIsSubmitEnabled(false);
    }
  };

  useEffect(() => {
    const fileInput = getFileInput();
    // Handle the file input change
    fileInput.onchange = (event) => {
      // Update the file name for display
      const resolve = (name: string, idpXml: string) => {
        form.setFieldsValue({ IdPXML: idpXml });
        setFileName(name);
        console.log('[handleFileButtonClick] File selected:', name);
        handleInputChange();
      };

      // TODO: Handle errors
      const reject = (e: unknown) => {
        console.log('Error:', e);
      };
      setReadOnlyXML(false);
      handleFileInputChange(event, resolve, reject);
    };
  }, [handleInputChange, form.setFieldsValue]);

  const handleFileInputChange = (
    event: Event,
    resolve: (name: string, idpXml: string) => void,
    reject: (error: unknown) => void,
  ) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (!file) {
      reject(new Error('No file selected.'));
      return;
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'xml') {
      const reader = new FileReader();

      reader.onerror = (e) => {
        console.log('Failed to read the file. Please try again.');
        reject(e);
      };

      reader.onload = () => {
        try {
          const text = reader.result as string;
          const encoder = new TextEncoder();
          const data = encoder.encode(text);
          const idpXml = btoa(String.fromCharCode(...new Uint8Array(data)));

          resolve(file.name, idpXml);
        } catch (error) {
          console.log('There was an issue processing the file. Please try again.');
          reject(error);
        }
      };

      reader.readAsText(file, 'UTF-8');
    } else {
      console.log('Please select an XML file.');
      reject(new Error('Please select an XML file.'));
    }
  };

  return (
    <div className="external-idp-container">
      <Form
        name="IdpInfo"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onValuesChange={handleInputChange}
      >
        <div className={'idp-subtitle'}>
          {t(`${TRANSLATION_ROOT}.subtitle`)}{' '}
          <a href="https://docs.imprivata.com/TBD" target="_blank" rel="noopener noreferrer">
            {t('common.see-instructions')}
          </a>
        </div>

        {/* Name */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-name-input`)} name={'IdPName'} rules={[{ required: true }]}>
          <InputBox autoFocus size="large" data-testid="idp-name-input" className={'input'} />
        </Form.Item>

        <Trans t={t} i18nKey={`${TRANSLATION_ROOT}.idp-meta-label`} />

        {/* URL */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-url-input`)} name={'IdPURL'}>
          <InputBox size="large" data-testid="idp-url-input" className={'input'} disabled={!!fileName} />
        </Form.Item>

        {/* IdP XML file */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.idp-xml-input`)} name={'IdPXML'}>
          <div className="xml-form-item">
            <InputBox
              size="large"
              data-testid="idp-xml-input"
              className={'input'}
              value={fileName}
              onChange={handleInputBoxChange}
              onFocus={handleInputBoxFocus}
              onKeyDown={handleInputBoxKeyDown}
              readOnly={readOnlyXML}
            />
            <Button
              type="text"
              className={'browse-button'}
              label={t(`${TRANSLATION_ROOT}.idp-xml-browse-button`)}
              data-testid="idp-xml-browse-button"
              size="middle"
              onClick={() => getFileInput().click()}
              disabled={isIdpUrl}
            />
          </div>
        </Form.Item>

        {/* Copy link */}
        <Form.Item label={t(`${TRANSLATION_ROOT}.sp-metadata-label`)}>
          <div className="link-content">
            <InputBox data-testid="idp-xml-input" className={'input'} value={spMetadataURL} readOnly />
            <CopyButton
              textToCopy={spMetadataURL?.trim()}
              dynamicLabel={{
                initialLabel: t('common.copy'),
                labelAfterClick: t('common.copied'),
                dynamicLabelDuration: 5000,
              }}
            />
          </div>
        </Form.Item>
        {children}
        {/* Submit button */}
        <div className={isModalView ? 'idp-form-button-container' : undefined}>
          <Form.Item shouldUpdate>
            <ContinueButton
              disabled={!isSubmitEnabled}
              htmlType="submit"
              label={!isModalView ? t('common.continue-button') : t('common.save')}
              validateDirty={['IdPName', 'IdPURL', 'IdPXML']}
            />
          </Form.Item>
          <Button
            label={!isModalView ? t('common.skip') : t('common.cancel')}
            onClick={() => {
              !isModalView
                ? sessionStorage.getItem(StorageKeys.INTEGRATION_TOKEN_USED)
                  ? navigateToNextStep(STEPS.ORG_INFO)
                  : setMustConnectModalVisible(true)
                : modalOnClose?.();
            }}
            size="large"
            style={{
              border: 'none',
              boxShadow: 'none',
              marginTop: '-1rem',
            }}
            block={!isModalView}
            data-testid="skip-button"
          />
        </div>
      </Form>
      <input id={FILE_INPUT_ID} type="file" accept=".xml" hidden />
      <Modal
        open={mustConnectModalVisible}
        destroyOnClose
        footer={null}
        onCancel={() => {
          setMustConnectModalVisible(false);
        }}
      >
        <div className="modal-you-must">
          <div style={{ margin: '.5rem' }}>
            <QuestionSvg style={{ width: '4rem' }} />
          </div>
          <h1>{t('setup.cannot-complete.text')}</h1>
          <div className="footer">
            <Button
              label={t('setup.cannot-complete.button-secondary')}
              data-testid="cannot-complete-btn-secondary"
              onClick={() => {
                window.location.href = 'https://www.imprivata.com';
              }}
            />
            <Button
              label={t('setup.cannot-complete.button-primary')}
              type="primary"
              data-testid="cannot-complete-btn-primary"
              onClick={() => navigateToNextStep(STEPS.START)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IdpInfoForm;

function getFileInput() {
  const fileInput = document.getElementById(FILE_INPUT_ID);
  // To stop IDE from complaining.
  if (!fileInput) {
    throw new Error('XML file input not found');
  }
  return fileInput;
}

// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import type { GetProp, UploadProps } from 'antd';
import type { SystemType } from '../../api/constants';
import * as api from '../../api/setupServices';
import { FileAccessModifier, type PrepareFileUploadResponse } from '../../api/types';
import type { AppError } from '../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../utils/tracer';

export type OrganizationInfoSaveVarsType = {
  orgName: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
  logoFile?: File;
  oldLogoUrl?: string;
};

function useOrganizationInfoSave(systemType: SystemType) {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, AppError, OrganizationInfoSaveVarsType>({
    mutationFn: async ({ orgName, usernameLabel, passwordLabel, proxCardLabel, logoFile, oldLogoUrl }) => {
      let uploadLinks: PrepareFileUploadResponse | undefined;

      if (logoFile) {
        try {
          tracer.startSpan(SPANS_SETUP.get_upload_url);
          uploadLinks = await api.getUploadUrl(systemType, logoFile.name, FileAccessModifier.PUBLIC);
          tracer.endSpan(SPANS_SETUP.get_upload_url);
        } catch (error) {
          tracer.endSpan(SPANS_SETUP.get_upload_url, { error });
          throw error;
        }

        try {
          tracer.startSpan(SPANS_SETUP.upload_file);
          await api.uploadFile(logoFile, uploadLinks?.uploadUrl || '');
          tracer.endSpan(SPANS_SETUP.upload_file);
        } catch (error) {
          tracer.endSpan(SPANS_SETUP.upload_file, { error });
          throw error;
        }
      }

      try {
        tracer.startSpan(SPANS_SETUP.save_org_info);
        await api.saveOrgInfo(systemType, {
          logoUrl: (uploadLinks?.readUrl || oldLogoUrl) as string,
          orgName: orgName,
          usernameLabel: usernameLabel,
          passwordLabel: passwordLabel,
          proxCardLabel: proxCardLabel,
        });
        tracer.endSpan(SPANS_SETUP.save_org_info);
      } catch (error) {
        tracer.endSpan(SPANS_SETUP.save_org_info, { error });
        throw error;
      }
    },
    onError: (error) => {
      throw error;
    },
  });
}

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export function getBase64(file: FileType): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => {
      reject(error);
      console.error('Image getBase64 failed.', error, file);
    };
  });
}

export default useOrganizationInfoSave;

import { Input, InputBox } from '@imprivata-cloud/components';
import { Col, Flex, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { StorageKeys } from '../../api/constants';
import ContinueButton from '../continueButton/ContinueButton';
import CopyButton from '../copyButton/copyButton';

export type ICCTokenFormValues = {
  integrationId: string;
};

type ICCTokenFormProps = {
  onSubmit: (values: ICCTokenFormValues) => void;
  translationRoot: string;
  installationTokenValue: string;
  isSetup?: boolean;
};

const ICCTokenForm = ({ onSubmit, translationRoot, installationTokenValue, isSetup }: ICCTokenFormProps) => {
  const { t } = useTranslation();
  const copyButtonLocalizedLabel = t(`${translationRoot}.${isSetup ? 'copy' : 'copy2'}`);
  const [form] = Form.useForm<ICCTokenFormValues>();

  return (
    <Form form={form} onFinish={onSubmit}>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={isSetup ? 24 : 14}>
          <Form.Item name={'integrationId'} rules={[{ required: true }]}>
            <InputBox label={t(`${translationRoot}.input`)} required autoFocus />
          </Form.Item>
          <ContinueButton
            validateDirty
            htmlType="submit"
            label={t(`${translationRoot}.button`)}
            data-testid="create-token-button"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Flex vertical>
            {!isSetup && (
              <Input.TextArea
                id="installationTokenValue"
                autoSize={{ minRows: 3, maxRows: 6 }}
                disabled
                value={installationTokenValue}
                label={t(`${translationRoot}.token-textarea-label`)}
              />
            )}
            <CopyButton
              textToCopy={installationTokenValue}
              onClick={() => sessionStorage.setItem(StorageKeys.INTEGRATION_TOKEN_USED, 'true')}
              block={isSetup}
              data-testid="copy-token-button"
              style={{
                visibility: !isSetup ? 'visible' : installationTokenValue ? 'visible' : 'hidden',
                alignSelf: !isSetup ? 'flex-end' : 'center',
                margin: '16px 0',
              }}
              disabled={!installationTokenValue}
              dynamicLabel={{
                initialLabel: copyButtonLocalizedLabel,
                labelAfterClick: t('common.copied'),
                dynamicLabelDuration: 5000,
              }}
            />
          </Flex>
        </Col>
      </Row>
      {isSetup && <p>{t(`${translationRoot}.text-2`)}</p>}
    </Form>
  );
};

export { ICCTokenForm };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationTokenPage } from '../../../common/integration-token/IntegrationTokenPage';
import { Col, Row } from 'antd';

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={24} md={24} lg={24} className="setup-content p-xxxl">
        <h1 style={{ color: 'black', marginTop: '0' }}>{t('common.integration-token.page-title')}</h1>
        <IntegrationTokenPage isSetup={false} />
      </Col>
    </Row>
  );
};

export default Integrations;

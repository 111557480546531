import { Button } from '@imprivata-cloud/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Endpoints } from '../../api/constants';
import { ICCTokenForm, type ICCTokenFormValues } from '../../components/iccToken/ICCTokenForm';
import { STEPS } from '../../setup/constants';
import useICCTokenMutation from '../../setup/hooks/useICCTokenMutation';
import { useNavigateToNextStep } from '../../setup/hooks/useNavigateToNextStep';

const TRANSLATION_ROOT = 'common.integration-token.content';

const IntegrationTokenPage = ({ isSetup = true }) => {
  const { t } = useTranslation();
  const navigateToNextStep = useNavigateToNextStep();
  const [installationTokenValue, setInstallationTokenValue] = useState('');

  const setToken = (token: string) => {
    setInstallationTokenValue(token);
  };

  const { mutate: onSubmit } = useICCTokenMutation({
    setToken,
    createTokenUrl: isSetup ? Endpoints.SETUP_CONNECT_CLIENT_CREATE : Endpoints.PORTAL_CONNECT_CLIENT_CREATE,
  });

  const handleFormSubmit = (values: ICCTokenFormValues) => {
    console.log('values:', values);
    onSubmit(values);
  };

  return (
    <>
      <p>
        {t(`${TRANSLATION_ROOT}.text-1`)}
        <a href="https://docs.imprivata.com/" target="_blank" rel="noopener noreferrer">
          {t('common.see-instructions')}
        </a>
      </p>
      <ICCTokenForm
        onSubmit={handleFormSubmit}
        translationRoot={TRANSLATION_ROOT}
        installationTokenValue={installationTokenValue}
        isSetup={isSetup}
      />
      {isSetup && (
        <>
          <Button
            size="large"
            type="primary"
            block
            onClick={() => navigateToNextStep(STEPS.EXTERNAL_IDP_INFO)}
            label={t('common.continue-button')}
            data-testid="continue-button"
          />
          <Button
            onClick={() => navigateToNextStep(STEPS.EXTERNAL_IDP_INFO)}
            size="large"
            style={{
              border: 'none',
              boxShadow: 'none',
            }}
            label={t('common.skip')}
            block
            data-testid="skip-button"
          />
        </>
      )}
    </>
  );
};

export { IntegrationTokenPage };

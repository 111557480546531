export type Metadata =
  | {
      rawMetadata: string;
      metadataUrl?: never;
    }
  | {
      rawMetadata?: never;
      metadataUrl: string;
    };

export interface IdpMetadataSaveRequest {
  displayName: string;
  metadata: Metadata;
}

export interface OrgPreferencesGetResponse extends OrgPreferencesUpdateRequest {}

export interface OrgPreferencesUpdateRequest {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
}

export interface DomainsGetResponse {
  names: string[];
}

export interface DomainsSetRequest {
  names: string[];
}

export interface GetAadConsentRequest {
  aadTenantId: string;
}

export interface GetAadConsentResponse {
  aadConsentUrl: string;
}

export interface TenantSetupCompleteRequest {
  email: string;
  withEntraId: boolean;
  consent?: ApiConsent;
}

export interface ApiConsent {
  grantType: string;
  documentName: string;
}

export interface TenantLookupResponse {
  tenantIds: string[];
}

export interface GetClientUserSessionResponse {
  tenantId?: string;
}

export interface EntraIdConsentUrlGetResponse {
  entraConsentUrl: string;
}

export interface EntraIdConsentUrlGetRequest {
  entraTenantId: string;
}

export interface EntraIdTenantSetupResponse {
  entraUserId: string;
}

export type OrgInfo = {
  name: string;
  logoUrl: string;
};

export type StartSsoLink = {
  startSsoUrl: string;
  applianceDesignator: string;
};

export type PurchasedProduct = {
  displayName: string;
  productTag: string;
  startSsoLinks: StartSsoLink[];
};

export type NonPurchasedProduct = {
  displayName: string;
  productTag: string;
  learnMoreUrl: string;
};

export type ProductInfo = {
  purchasedProducts: PurchasedProduct[];
  nonPurchasedProducts: NonPurchasedProduct[];
};

export interface GetLandingPageResponse {
  orgInfo?: OrgInfo;
  productInfo: ProductInfo;
}

export enum FileAccessModifier {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export type PrepareFileUploadResponse = {
  uploadUrl: string;
  readUrl: string;
};

export interface FormValues {
  IdPName: string;
  IdPURL: string;
  IdPXML: string;
}
// DPA
export interface CheckDpaConsentRequest {
  consentDocumentName: string;
}
export interface CheckDpaConsentResponse {
  actionType: CheckDpaConsentResponseActionType;
}
export enum CheckDpaConsentResponseActionType {
  NONE = 'NONE', // Don't show the clickthrough
  CHECK_NEEDED = 'CHECK_NEEDED',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
}

// create token
export interface ClientCreateRequest {
  enterpriseId: string;
  installationTokenExpirationInMinutes: number;
}

export interface ClientCreateResponse {
  clientMetadata: ClientMetadata;
}

export type ClientMetadata = {
  clientId: string;
  eamEnterpriseId: string;
  installationToken: TokenSecret;
  tokenExpiryTime: number;
  createdAt: number;
  installationTokenValue: string;
};
export type TokenSecret = {
  data: string;
  origin: null;
  isProxied: false;
};

export type GroupRequest = {
  groupName: string;
  groupId: string;
  userCount: number;
  syncEnabled: boolean;
  adminEnabled: boolean;
  idpDeleted: boolean;
};

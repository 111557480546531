import { Button } from '@imprivata-cloud/components';
import React from 'react';
import type { FC, ReactElement } from 'react';
import AIPLogo from '../../../assets/aip_logo.svg?react';
import EAMLogo from '../../../assets/Identity_logo.svg?react';
import MAMLogo from '../../../assets/mam_logo.svg?react';
import Hexagon from '../../../assets/hexagon.svg?react';
import classes from './PurchasedProductCard.module.less';
import type { PurchasedProduct } from '../../../api/types';
import { Dropdown, type MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type Props = {
  product: PurchasedProduct;
};

export const PurchasedProductCard: FC<Props> = ({ product }) => {

  const onProductClick = () => {
    // open product in new tab
    window.open(product.startSsoLinks[0].startSsoUrl, '_blank', 'noopener,noreferrer');
  };

  let icon: ReactElement;
  switch (product.productTag) {
    case "access-intel-platform":
      icon = <AIPLogo />;
      break;
    case "mobile-access-mgmt":
      icon = <MAMLogo />;
      break;
    case "enterprise-access-mgmt":
      icon = <EAMLogo />;
      break;
    default:
      icon = <EAMLogo />;
  }

  const items: MenuProps['items'] = product.startSsoLinks
    .map((startSsoLink) => {
      return {
        key: startSsoLink.applianceDesignator,
        label: (
          <a target="_blank" rel="noopener noreferrer" href={startSsoLink.startSsoUrl}>
            {startSsoLink.applianceDesignator}
          </a>
        ),
      }
    });

  return (
    <div className={classes.card}>
      <div className={classes.card}>
        <div className={classes.productHeaderContainer}>
          <div className={classes.hexagonContainer}>
            <Hexagon className={classes.hexagon} />
            <div className={classes.productIconContainer}>
              {React.cloneElement(icon, { style: { width: '100%', height: '100%' } })}
            </div>
          </div>
        </div>

        <div className={classes.titleContainer}>
          <h3 className={classes.title}>{product.displayName}</h3>
        </div>
        <div className={classes.linkContainer}>
          {product.startSsoLinks.length < 2
            ? <Button onClick={onProductClick} label={'Launch'} type={'text'} />
            :
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button type={'text'}>Launch <DownOutlined /></Button>
            </Dropdown>
          }
        </div>
      </div>
    </div>
  );
};

import { Button, DialogVariant, ImprDialog, InputBox } from '@imprivata-cloud/components';
import { Form, Modal } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getEntraIdConsentUrl } from '../../../api/portalServices';
import type { FormValues } from '../../../api/types';
import AddEntraIdIcon from '../../../assets/add_entraid_icon.svg?react';
import SamlIcon from '../../../assets/saml.svg?react';
import IdpInfoForm from '../../../components/idpInfoForm/IdpInfoForm';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import ConfigEntraID from '../config-entraID/configEntraID';

const TRANSLATION_ROOT = 'portal.config-entra-id';

const Directories = () => {
  const { t } = useTranslation();
  const [addSamlModalVisible, setAddSamlModalVisible] = useState(false);
  const [samlConfigForm] = Form.useForm<FormValues>();
  const location = useLocation();
  const tenantId = location.state?.tenantId;
  const { emitError } = useNotifications();
  const [addEntraIdVisible, setAddEntraIdVisible] = useState(false);
  const [entraIdForm] = Form.useForm();
  const entraIdValue = useWatch('entraId', { form: entraIdForm });
  const isEntraIdValid = Boolean(entraIdValue);

  const sumbitSamlConfig = () => {
    const values = samlConfigForm.getFieldsValue() as FormValues;
    // TODO add BFF interactions and validations
    console.log(values);
  };

  const redirectToMicrosoft = async () => {
    try {
      const entraId = entraIdForm.getFieldValue('entraId');
      const consentUrlData = await getEntraIdConsentUrl({ entraTenantId: entraId }, tenantId);
      if (consentUrlData?.entraConsentUrl) {
        window.location.href = consentUrlData?.entraConsentUrl;
      } else {
        console.error('Error fetching Entra Id consent url:', consentUrlData);
        emitError(new AppError({ code: ErrorCode.UNKNOWN, message: 'Error fetching Entra Id consent url' }));
      }
    } catch (error) {
      console.error('Error fetching Entra Id consent url:', error);
      emitError(error as AppError);
    }
  };

  const closeIdpModal = () => {
    samlConfigForm.resetFields();
    setAddSamlModalVisible(false);
  };

  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <Button type="text" icon={<SamlIcon />} onClick={() => setAddSamlModalVisible(true)}>
          {t('portal.settings.directories.add-saml-button')}
        </Button>
        <Button type="text" icon={<AddEntraIdIcon />} onClick={() => setAddEntraIdVisible(true)}>
          {t('portal.settings.directories.entra-id.button-label')}
        </Button>
      </div>
      {/* Add SAML config modal */}
      <Modal
        styles={{ header: { backgroundColor: '#F4F5F7' } }}
        centered
        title={<div className="modal-header-content h3">{t('portal.settings.directories.add-saml-modal-header')}</div>}
        open={addSamlModalVisible}
        width={825}
        destroyOnClose
        footer={null}
        onCancel={closeIdpModal}
      >
        <IdpInfoForm form={samlConfigForm} isModalView={true} modalOnClose={closeIdpModal} onFinish={sumbitSamlConfig}>
          <Form.Item name="AdminGroupsName" label={t('portal.settings.directories.admin-groups-name')}>
            <InputBox />
          </Form.Item>
          <Form.Item name="AdminGroupsValues" label={t('portal.settings.directories.admin-groups-values')}>
            <InputBox />
          </Form.Item>
        </IdpInfoForm>
      </Modal>
      {/* Entra ID Modal */}
      <ImprDialog
        open={addEntraIdVisible}
        type={DialogVariant.HEADER_DUAL_BUTTON}
        width={710}
        submitButtonProps={{
          label: t(`${TRANSLATION_ROOT}.continue-to-microsoft`),
          disabled: !isEntraIdValid,
          onClick: redirectToMicrosoft,
        }}
        cancelButtonProps={{ label: t(`${TRANSLATION_ROOT}.cancel`), onClick: () => setAddEntraIdVisible(false) }}
        title={t(`${TRANSLATION_ROOT}.title`)}
        content={<ConfigEntraID tenantId={tenantId} entraIdForm={entraIdForm} />}
      />
    </>
  );
};

export default Directories;

// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { InputBox } from '@imprivata-cloud/components';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../api/portalServices';
import CopyButton from '../../../components/copyButton/copyButton';
import './configEntraID.less';
import { Form } from 'antd';
import type { FormInstance } from 'antd/lib';

const TRANSLATION_ROOT = 'portal.config-entra-id';

type ConfigEntraIDProps = {
  tenantId: string;
  entraIdForm: FormInstance;
};

const ConfigEntraID = ({ tenantId, entraIdForm }: ConfigEntraIDProps) => {
  const { t } = useTranslation();
  const [entraId, setEntraId] = useState('');

  // Fetch IP ranges
  const ipRangeQuery = useQuery({
    queryKey: ['ipRanges', tenantId],
    queryFn: async () => await api.getIpRanges(tenantId),
    refetchOnWindowFocus: true,
  });

  if (ipRangeQuery.isError) {
    console.error('Error fetching IP ranges:', ipRangeQuery.isError);
  }

  useEffect(() => {
    const inputElement = document.getElementById('entra-id-input') as HTMLInputElement;
    inputElement?.focus();
  }, []);

  const handleEntraIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setEntraId(trimmedValue);
    entraIdForm.setFieldsValue({ entraId: trimmedValue });
  };

  return (
    <div className="modal-config-entra-id">
      <p className="instructions">{t(`${TRANSLATION_ROOT}.instructions`)}</p>

      {/* INPUT */}
      <div className="component-title">{t(`${TRANSLATION_ROOT}.entra-id`)}</div>
      <Form form={entraIdForm}>
        <Form.Item name={'entraId'} rules={[{ required: true, message: '' }]}>
          <InputBox
            id="entra-id-input"
            autoFocus
            size="large"
            data-testid="entra-id-input"
            className={'entra-id-input'}
            value={entraId}
            onChange={handleEntraIdChange}
          />
        </Form.Item>
      </Form>

      {/* IP Ranges */}
      <div className="component-title">{t(`${TRANSLATION_ROOT}.ip-ranges`)}</div>
      <div className="ip-ranges-container">
        <div className="ip-ranges-box">
          {ipRangeQuery.data?.map((ip) => (
            <div key={ip} className="ip-range-item">
              {ip}
            </div>
          ))}
        </div>

        {/* Copying IP Ranges */}
        <CopyButton
          textToCopy={ipRangeQuery.data?.join('\r\n') || ''}
          size="small"
          block
          dynamicLabel={{
            initialLabel: t(`${TRANSLATION_ROOT}.copy-all`),
            labelAfterClick: t('common.copied'),
            dynamicLabelDuration: 5000,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigEntraID;

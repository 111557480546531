import { useState } from 'react';
import ExternalIdpGroups from './ExternalIdpGroups';
import ExternalIdpInfo from './ExternalIdpInfo';

const ExternalIdpStep = () => {
  const [idpInfoSaved, setIdpInfoSaved] = useState(false);
  return idpInfoSaved ? <ExternalIdpGroups /> : <ExternalIdpInfo setIdpInfoSaved={setIdpInfoSaved} />;
};

export default ExternalIdpStep;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { IntegrationTokenPage } from '../common/integration-token/IntegrationTokenPage';
import PageLayoutView from '../components/layout/PageLayoutView';
import { ErrorView } from '../errorHandler/ErrorView';
import { STEPS } from './constants';
import ConnectEntraId from './containers/connect-entraid/ConnectEntraId';
import DataProcessingAddendumPage from './containers/data-processing-addendum/DataProcessingAddendumPage';
import ExternalIdpStep from './containers/external-idp-info/ExternalIdpStep';
import SetupLandingPage from './containers/home/SetupLandingPage';
import IdpChoice from './containers/idp/IdpChoice';
import OrganizationRoute from './containers/organization-info/OrganizationRoute';
import SetupComplete from './containers/setup-complete/SetupComplete';
import TrustImprivataServers from './containers/trust-imprivata-servers/TrustImprivataServers';
import UpdateConditionalPolicies from './containers/update-conditional-policies/UpdateConditionalPolicies';
import Setup from './index';

const SetupRoutes = () => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PageLayoutView title={'Setup - Imprivata Access Management'}>
            <Setup />
          </PageLayoutView>
        }
      >
        <Route index element={<SetupLandingPage />} />
        <Route path={STEPS.DATA_PROCESSING_ADDENDUM} element={<DataProcessingAddendumPage />} />
        <Route path={STEPS.INTEGRATION_TOKEN} element={<IntegrationTokenPage />} />
        <Route path={STEPS.ORG_INFO} element={<OrganizationRoute />} />
        <Route path={STEPS.IDP_CHOICE} element={<IdpChoice />} />
        <Route path={STEPS.EXTERNAL_IDP_INFO} element={<ExternalIdpStep />} />
        <Route path={STEPS.UPDATE_CONDITIONAL_POLICIES} element={<UpdateConditionalPolicies />} />
        <Route path={STEPS.TRUST_IMPRIVATA_SERVERS} element={<TrustImprivataServers />} />
        <Route path={STEPS.CONNECT_ENTRA_ID} element={<ConnectEntraId />} />
        <Route path={STEPS.SETUP_COMPLETE} element={<SetupComplete />} />
        <Route path={STEPS.ERROR} element={<ErrorView />} />
      </Route>
    </Routes>
  );
};

export default SetupRoutes;

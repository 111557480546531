import { Button } from '@imprivata-cloud/components';
import SettingsIcon from '../../../assets/settings.svg?react';
import PageHeader from '../../../components/layout/PageHeader';
import './PortalLanding.less';

type LandingPageHeaderProps = {
  userLogout: () => void;
  handleSettings?: () => void;
};

const LandingPageHeader = ({ userLogout, handleSettings }: LandingPageHeaderProps) => {
  return (
    <>
      <PageHeader
        title={'Imprivata Access Management'}
        className={'page-header'}
        rightSlot={
          <div className={'landing-header-content'}>
            <Button icon={<SettingsIcon />} className={'settings-button'} onClick={handleSettings} />
            <Button type={'text'} onClick={userLogout}>
              Logout
            </Button>
          </div>
        }
      />
    </>
  );
};

export default LandingPageHeader;

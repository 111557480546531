import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PortalLanding } from './containers/landing/PortalLanding';
import PortalLayout from './containers/layout/PortalLayout';
import Settings from './containers/settings/Settings';
import Portal from './index';

const PortalRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Portal />}>
        <Route
          index
          element={
            <PortalLayout>
              <PortalLanding />
            </PortalLayout>
          }
        />
        <Route
          path={'/settings'}
          element={
            <PortalLayout>
              <Settings />
            </PortalLayout>
          }
        />
        <Route path={'/entraid'} element={<Portal />}>
          <Route
            path={'/entraid/auth-success'}
            element={
              <PortalLayout>
                <Settings activeTab={'directories'} />
              </PortalLayout>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default PortalRoutes;

import { useMutation } from '@tanstack/react-query';
import { startEntraIdInitialSync } from '../../api/portalServices';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../errorHandler/errors';
import { useSession } from '../context/Session';
import useEntraIdSetupComplete from './useEntraIdSetupComplete';

const useEntraIdInitialSync = () => {
  const { emitError } = useNotifications();
  const { tenantId } = useSession();
  const { mutateAsync: completeEntraIdSetup } = useEntraIdSetupComplete();

  return useMutation({
    mutationFn: async (tenantId: string) => {
      await startEntraIdInitialSync(tenantId);
    },
    onSuccess: async () => {
      console.log('Entra ID iniital sync completed');
      await completeEntraIdSetup(tenantId || '');
    },
    onError: (e: Error) => {
      console.log('Entra ID initial sync failed', e);
      const apiError = e as unknown as ApiError;
      emitError(new AppError(apiError));
    },
  });
};

export default useEntraIdInitialSync;

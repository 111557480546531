import type React from 'react';
import './PageLayout.less';
import { Button } from '@imprivata-cloud/components';
import { Layout } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ImprivataLogo from '../../assets/Imprivata_Logo.svg?react';

export type PageHeaderProps = {
  title: string;
  className?: string;
  rightSlot?: React.ReactNode;
  logoContainerStyles?: React.CSSProperties;
};

const PageHeader = ({ title, rightSlot, className }: PageHeaderProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { Header } = Layout;

  const navigateToHome = () => {
    const params = new URLSearchParams(searchParams);
    navigate({
      pathname: '/',
      search: `?${params.toString()}`,
    });
  };

  return (
    <Header className={'header'}>
      <div className={`logo-container ${className || ''}`}>
        <div className={'header-left-content'}>
          <Button type={'text'} icon={<ImprivataLogo fill={'#2C3F57'} />} onClick={navigateToHome} className={'logo'} />
          <span className={'logo-text'}>{title}</span>
        </div>
        <div className={'header-right-content'}>{rightSlot}</div>
      </div>
    </Header>
  );
};
export default PageHeader;

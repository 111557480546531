import { useMutation } from '@tanstack/react-query';
import { completeEntraIdSetup } from '../../api/portalServices';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../errorHandler/errors';

const useEntraIdSetupComplete = () => {
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async (tenantId: string) => {
      await completeEntraIdSetup(tenantId);
    },
    onSuccess: async () => {
      console.log('Entra ID setup completed');
    },
    onError: (e: Error) => {
      console.log('Entra ID setup failed', e);
      const apiError = e as unknown as ApiError;
      emitError(new AppError(apiError));
    },
  });
};

export default useEntraIdSetupComplete;

import { Select } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateToNextStep } from '../../hooks/useNavigateToNextStep';
import { IdpChoices } from '../../../api/constants';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS, TRANSLATION_BUTTON } from '../../constants';

const TRANSLATION_ROOT = `setup.${STEPS.IDP_CHOICE}.content`;

const IdpChoice = () => {
  const { t } = useTranslation();
  const [selectedIdp, setSelectedIdp] = useState<(typeof IdpChoices)[keyof typeof IdpChoices]>(
    IdpChoices.ENTRA_ID_VALUE,
  );
  const navigateToNextStep = useNavigateToNextStep();

  const idpOptions = useMemo(
    () => [
      {
        value: IdpChoices.EXTERNAL_VALUE,
        label: t(`${TRANSLATION_ROOT}.external-choice`),
      },
      {
        value: IdpChoices.NONE_VALUE,
        label: t(`${TRANSLATION_ROOT}.external-choice-none`),
      },
    ],
    [t],
  );

  const handleNextStep = () => {
    navigateToNextStep(selectedIdp === IdpChoices.NONE_VALUE ? STEPS.SETUP_COMPLETE : STEPS.EXTERNAL_IDP_INFO);
  };

  return (
    <>
      <Form layout="vertical">
        <div style={{ marginTop: '4rem' }}>
          <Form.Item>
            <Select
              autoFocus
              defaultValue={IdpChoices.EXTERNAL_VALUE}
              options={idpOptions}
              onChange={(value) => setSelectedIdp(value as (typeof IdpChoices)[keyof typeof IdpChoices])}
            />
          </Form.Item>
        </div>
        <ContinueButton onClick={handleNextStep}>{t(TRANSLATION_BUTTON)}</ContinueButton>
      </Form>
    </>
  );
};

export default IdpChoice;

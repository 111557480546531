// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { LocaleProvider } from './i18n';
import SetupRoutes from './setup/SetupRoutes';
import './styles/index.less';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from './errorHandler/context/Notifications';
import PortalRoutes from './portal/PortalRoutes';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
const queryClient = new QueryClient();

// App entrypoint
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Notifications translationRoot={'portal'} key={'pathKey'}>
        <Routes>
          <Route path={'setup/*'} element={<SetupRoutes />} />
          <Route path="*" element={<PortalRoutes />} />
        </Routes>
      </Notifications>
    </QueryClientProvider>
  );
};

// Render the application
root.render(
  <React.StrictMode>
    <LocaleProvider>
      <Router>
        <App />
      </Router>
    </LocaleProvider>
  </React.StrictMode>,
);

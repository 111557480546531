// Copyright 2025, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, type ButtonProps } from '@imprivata-cloud/components';
import copyIcon from '../../assets/icons/copy.svg?react';
import { copyToClipboard } from '../../utils/utils';
import './copyButton.less';

interface CopyButtonProps extends ButtonProps {
  textToCopy: string;
  dynamicLabel?: { initialLabel: string; labelAfterClick: string; dynamicLabelDuration?: number };
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  label,
  icon = <Icon component={copyIcon} />,
  className = '',
  size = 'small',
  dynamicLabel,
  onClick,
  ...rest
}) => {
  const [labelText, setLabelText] = useState(label || dynamicLabel?.initialLabel);

  const handleDynamicLabel = () => {
    setLabelText(dynamicLabel?.labelAfterClick);
    setTimeout(() => {
      setLabelText(dynamicLabel?.initialLabel);
    }, dynamicLabel?.dynamicLabelDuration);
  };

  return (
    <Button
      type="text"
      size={size}
      label={labelText}
      icon={icon}
      onClick={(event) => {
        onClick?.(event);
        dynamicLabel && handleDynamicLabel();
        copyToClipboard(textToCopy);
      }}
      className={`copy-button ${className}`}
      {...rest}
    />
  );
};

export default CopyButton;

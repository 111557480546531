import { useMutation } from '@tanstack/react-query';
import { createToken } from '../../api/setupServices';
import type { ICCTokenFormValues } from '../../components/iccToken/ICCTokenForm';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../utils/tracer';

export type ICCTokenProps = {
  createTokenUrl: string;
  setToken: (token: string) => void;
};

const useICCTokenMutation = ({ setToken, createTokenUrl }: ICCTokenProps) => {
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: (values: ICCTokenFormValues) => {
      // commented out since it doesn't work in Portal flow
      // tracer.startSpan(SPANS_SETUP.connect_client_create);
      return createToken(
        {
          enterpriseId: values.integrationId,
          installationTokenExpirationInMinutes: 60,
        },
        createTokenUrl,
      );
    },
    onSuccess: (clientCreateResponse) => {
      setToken(clientCreateResponse?.clientMetadata?.installationTokenValue);
      tracer.endSpan(SPANS_SETUP.connect_client_create);
    },
    onError: (e: ApiError) => {
      tracer.endSpan(SPANS_SETUP.connect_client_create, { error: e });
      console.error(SPANS_SETUP.connect_client_create, e);
      emitError(new AppError(e));
    },
  });
};

export default useICCTokenMutation;

import type { ApiErrorCommon } from '@imprivata-cloud/common';
import { ErrorCode, ErrorMessage } from './constants';

export interface ApiError extends ApiErrorCommon<ErrorCode> {}

// Base Class for application errors
export class AppError extends Error {
  static {
    // biome-ignore lint/complexity/noThisInStatic: <explanation>
    this.prototype.name = 'AppError';
  }

  public details: ApiError;
  public params?: Record<string, unknown>;
  public code?: ErrorCode;

  constructor(e: ApiError, params?: Record<string, unknown>) {
    super(e?.message || e?.code || ErrorMessage.SYSTEM_ERROR);
    this.details = e instanceof AppError ? e.details : e;
    this.params = params || (e as AppError).params;
    this.code = e?.code || this.details.code || ErrorCode.UNKNOWN;
  }

  static fromMessage(msg: string, code?: ErrorCode) {
    return new AppError({
      code: code || ErrorCode.UNKNOWN,
      message: msg,
    });
  }
}

/**
 * NOTE: Base Error Types - All App Errors should be one of these 3
 */

// FatalError means full page error display, cannot authenticate

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class FatalError extends AppError {
  static {
    // biome-ignore lint/complexity/noThisInStatic: <explanation>
    this.prototype.name = 'FatalError';
  }
}
